<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        class="pb-0"
      >
        <v-textarea
          label="Código do município pertence a lista"
          v-model="codMunicipioIbge"
          rows="3"
          no-resize
          class="mb-0"
          hint="Um código por linha"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="pb-0"
      >
        <v-textarea
          label="Nome do município pertence a lista"
          v-model="nomeMunicipioIbge"
          rows="3"
          no-resize
          class="mb-0"
          hint="Um município por linha"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="pb-0"
      >
        <v-textarea
          label="UF pertence a lista"
          v-model="uf"
          rows="3"
          no-resize
          class="mb-0"
          hint="Uma UF por linha"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="pb-0"
      >
        <municipios-data-table
          :month="month"
          :bdgdVersion="bdgdVersion"
          @itemsChanged="municipiosAtualizados"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'FormMunicipioIbgeConfig',

  components: {
    MunicipiosDataTable: () =>
      import('@/components/setorizacoes/MunicipiosDataTable')
  },

  props: {
    month: {
      type: String
    },
    bdgdVersion: {
      type: Number
    },
    algoritmoConfig: {
      type: Object
    }
  },

  mounted() {
    if (this.algoritmoConfig) this.fillInputs();
  },

  data() {
    return {
      numMunicipios: 0,
      codMunicipioIbge: null,
      nomeMunicipioIbge: null,
      uf: null
    };
  },

  watch: {
    numMunicipios() {
      this.algoritmoConfigAtualizado();
    },
    codMunicipioIbge() {
      this.algoritmoConfigAtualizado();
    },
    nomeMunicipioIbge() {
      this.algoritmoConfigAtualizado();
    },
    uf() {
      this.algoritmoConfigAtualizado();
    }
  },

  methods: {
    municipiosAtualizados(event) {
      this.numMunicipios = event.numItems;
    },

    algoritmoConfigAtualizado() {
      this.$emit('algoritmoConfigAtualizado', {
        numMunicipios: this.numMunicipios,
        codMunicipioIbge: this.codMunicipioIbge,
        nomeMunicipioIbge: this.nomeMunicipioIbge,
        uf: this.uf
      });
    },
    fillInputs() {
      this.numMunicipios = this.algoritmoConfig.numMunicipios;
      this.codMunicipioIbge = this.algoritmoConfig.codMunicipioIbge;
      this.nomeMunicipioIbge = this.algoritmoConfig.nomeMunicipioIbge;
      this.uf = this.algoritmoConfig.uf;
    }
  }
};
</script>
